<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('bsri_report.center_summary_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" md="6" sm="12">
                          <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="fiscal_year_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('elearning_config.fiscal_year') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  id="fiscal_year_id"
                                  plain
                                  v-model="search.fiscal_year_id"
                                  :options="fiscalYearList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                          <ValidationProvider name="Mill Name" vid="mill_info_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="mill_info_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('bsri_config.mill_name') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  id="mill_info_id"
                                  plain
                                  v-model="search.mill_info_id"
                                  :options="millInfoList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                          <ValidationProvider name="Subzone Office" vid="subzone_office_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="subzone_office_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('bsri_config.subzone_office_name')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  id="subzone_office_id"
                                  plain
                                  v-model="search.subzone_office_id"
                                  :options="subzoneOfficeList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                          <ValidationProvider name="Center" vid="center_id">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="center_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('bsri_config.center_name')}}
                                  </template>
                                  <b-form-select
                                  id="center_id"
                                  plain
                                  v-model="search.center_id"
                                  :options="centerList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col sm="12">
                        <b-button type="submit" variant="primary float-right" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('bsri_report.center_summary_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button variant="primary btn_add_new" @click="pdfExport" class="mr-2">
                            {{  $t('globalTrans.print') }}
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay :show="loading">
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="8">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        <span style="text-decoration: underline;">{{ $t('bsri_report.center_summary_report') }}</span>
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <div class="text-black">
                                    <b-row>
                                      <b-col><strong>{{ $t('elearning_config.fiscal_year') }}: </strong>{{ search.fiscal_year_id ? ($i18n.locale === 'en' ? searchResult.fiscal_year : searchResult.fiscal_year_bn) : $t('globalTrans.all')  }}</b-col>
                                      <b-col><strong>{{ $t('bsri_demandDIS.mill_name') }}: </strong>{{ search.mill_info_id  ?  ($i18n.locale === 'en' ? searchResult.mill_name : searchResult.mill_name_bn) : $t('globalTrans.all') }}</b-col>
                                      <b-col><strong>{{ $t('bsri_demandDIS.subzone_office_name') }}: </strong>{{ search.subzone_office_id  ?  ($i18n.locale === 'en' ? searchResult.subzone_office_name : searchResult.subzone_office_name_bn) : $t('globalTrans.all') }}</b-col>
                                      <b-col><strong>{{ $t('bsri_config.center_name') }}: </strong>{{ search.center_id ? ($i18n.locale === 'en' ? searchResult.center_name : searchResult.center_name_bn) : $t('globalTrans.all') }}</b-col>
                                    </b-row>
                                  </div>
                                  <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive>
                                        <b-thead>
                                          <tr>
                                            <th style="vertical-align : middle;text-align:center;" rowspan="3">{{ $t('globalTrans.sl_no') }}</th>
                                            <th style="vertical-align : middle;text-align:center;" rowspan="3">{{ $t('bsri_config.center_name') }}</th>
                                            <th style="vertical-align : middle;text-align:center;" rowspan="3">{{ $t('bsri_demandDIS.total_farmer') }}</th>
                                            <th class="text-center" :colspan="(cultivationMethod.length * 2) + 2">{{ $t('bsri_config.cultivation_method_name') }}</th>
                                          </tr>
                                           <tr>
                                              <th colspan="2" v-for="(item, index) in cultivationMethod" :key="index">
                                                 {{ $i18n.locale === 'bn' ? item.cultivationMethod_name_bn :  item.cultivationMethod_name }}
                                                  ( {{ $t('bsri_demandDIS.acr_unit_first') }} {{ $n(item.per_acr_amount) }} {{ $t('bsri_demandDIS.acr_unit_second') }} )
                                              </th>
                                              <th style="vertical-align : middle;text-align:center;" colspan="2">{{ $t('bsri_demandDIS.total') }}</th>
                                          </tr>
                                          <tr>
                                            <template v-for="(item2, index2) in cultivationMethod">
                                              <th :key="index2+1">{{ $t('bsri_demandDIS.lnd_quantity') }}</th>
                                              <th :key="index2+2"> {{ $t('bsri_demandDIS.amount_money') }}</th>
                                            </template>
                                            <th>{{ $t('bsri_demandDIS.lnd_quantity') }}</th>
                                            <th>{{ $t('bsri_demandDIS.amount_money') }}</th>
                                          </tr>
                                        </b-thead>
                                        <b-tbody  v-for="(info, index3) in datas" :key="index3">
                                          <b-td class="text-center">
                                            {{  $n(index3 + 1) }}
                                          </b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.center_name_bn : info.center_name }}</b-td>
                                          <b-td class="text-center">{{ $n(info.total_farmers) }}</b-td>
                                          <template v-for="(item4, index4) in cultivationMethod">
                                            <td :key="info.center_id + index4">{{ info.cultivation_info[index4 + 1] ? $n(info.cultivation_info[index4 + 1].land_quantity) : $i18n.locale === 'bn' ? '০' : '0' }}</td>
                                            <td :key="info.center_id + index4"> {{ info.cultivation_info[index4 + 1] ? $n(info.cultivation_info[index4 + 1].amount) : $i18n.locale === 'bn' ? '০' : '0' }}</td>
                                            </template>
                                          <b-td class="text-center">{{ $n(info.total_quantity) }}</b-td>
                                          <b-td class="text-center">{{ $n(info.total_amount) }}</b-td>
                                        </b-tbody>
                                        <b-tfoot v-if="datas.length > 0">
                                          <b-td colspan="2" class="text-center">{{ $t('globalTrans.total') }}</b-td>
                                          <b-td class="text-center">{{ $n(grandTotal.grand_total_farmer) }}</b-td>
                                          <b-td v-if="cultivationMethod.length > 0" :colspan="cultivationMethod.length * 2"></b-td>
                                          <b-td class="text-center">{{ $n(grandTotal.grand_total_quantity) }}</b-td>
                                          <b-td class="text-center">{{ $n(grandTotal.grand_total_amount) }}</b-td>
                                        </b-tfoot>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { centerWiseSummaryReport } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './pdf'

export default {
    components: {
      ListReportHead
    },
    data () {
        return {
          search: {
            fiscal_year_id: 0,
            mill_info_id: 0,
            subzone_office_id: 0,
            center_id: 0
          },
          searchResult: {
            fiscal_year: '',
            fiscal_year_bn: '',
            mill_name: '',
            mill_name_bn: '',
            subzone_office_name: '',
            subzone_office_name_bn: '',
            center_name: '',
            center_name_bn: ''
          },
          incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
          datas: [],
          grandTotal: {},
          loading: false,
          showData: false,
          cultivationMethod: [],
          subzoneOfficeList: [],
          centerList: []
        }
    },
    mounted () {
      core.index()
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      millInfoList: function () {
        return this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
      }
    },
    watch: {
      'search.mill_info_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.subzoneOfficeList = this.getSubzoneOfficeList(newVal)
        }
      },
      'search.subzone_office_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.centerList = this.getCenterList(newVal)
        }
      }
    },
    methods: {
      pdfExport () {
        const reportTitle = this.$t('bsri_report.center_summary_report')
        ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 8, this.$i18n.locale, reportTitle, this.datas, this, this.search, this.searchResult, this.grandTotal)
      },
      async searchData () {
        if (this.search.fiscal_year_id) {
          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYear => fiscalYear.value === parseInt(this.search.fiscal_year_id))
          this.searchResult.fiscal_year = fiscalYearObj !== undefined ? fiscalYearObj.text_en : ''
          this.searchResult.fiscal_year_bn = fiscalYearObj !== undefined ? fiscalYearObj.text_bn : ''
        }
        if (this.search.mill_info_id) {
          const millNameObj = this.$store.state.incentiveGrant.commonObj.millInfoList.find(millItem => millItem.value === parseInt(this.search.mill_info_id))
          this.searchResult.mill_name = millNameObj !== undefined ? millNameObj.text_en : ''
          this.searchResult.mill_name_bn = millNameObj !== undefined ? millNameObj.text_bn : ''
        }
        if (this.search.subzone_office_id) {
          const subzoneNameObj = this.$store.state.incentiveGrant.commonObj.subZoneList.find(subzoneItem => subzoneItem.value === parseInt(this.search.subzone_office_id))
          this.searchResult.subzone_office_name = subzoneNameObj !== undefined ? subzoneNameObj.text_en : ''
          this.searchResult.subzone_office_name_bn = subzoneNameObj !== undefined ? subzoneNameObj.text_bn : ''
        }
        if (this.search.center_id) {
          const centerObj = this.$store.state.incentiveGrant.commonObj.centerList.find(cntr => cntr.value === parseInt(this.search.center_id))
          this.searchResult.center_name = centerObj !== undefined ? centerObj.text_en : ''
          this.searchResult.center_name_bn = centerObj !== undefined ? centerObj.text_bn : ''
        }

        this.cultivationMethod = this.customCultivationInfo()
        this.loading = true
        this.showData = true
        const params = Object.assign({}, this.search)
        await RestApi.getData(incentiveGrantServiceBaseUrl, centerWiseSummaryReport, params).then(response => {
          if (response.success) {
            if (response.data) {
              this.datas = this.getRelationData(response.data)
              this.grandTotal = response.grand_total
            }
          } else {
            this.datas = []
            this.grandTotal = {}
          }
        })
        this.loading = false
      },
      getRelationData (data) {
        const centerStore = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item.status === 1)

        const listData = data.map(item => {
          const centerObj = centerStore.find(cntr => cntr.value === item.center_id)

          const centerData = {
            center_name: centerObj !== undefined ? centerObj.text_en : ' ',
            center_name_bn: centerObj !== undefined ? centerObj.text_bn : ' '
          }
          return Object.assign({}, item, centerData)
        })

        return listData
      },
      customCultivationInfo () {
        const cultivationPolicyList = this.$store.state.incentiveGrant.commonObj.cultivationPolicy.filter(item => item.fiscal_year_id === this.search.fiscal_year_id)
        const listData = cultivationPolicyList.map(item => {
          const cultivationMethodObject = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.find(methodItem => methodItem.value === item.method_cultivation_id)

          const cultivationMethodData = {
            cultivationMethod_name: cultivationMethodObject !== undefined ? cultivationMethodObject.text_en : ' ',
            cultivationMethod_name_bn: cultivationMethodObject !== undefined ? cultivationMethodObject.text_bn : ' '
          }
          return Object.assign({}, item, cultivationMethodData)
        })

        return listData
      },
      getSubzoneOfficeList (millInfoId) {
        return this.$store.state.incentiveGrant.commonObj.subzoneOfficeList.filter(item => item.status === 1 && item.mill_info_id === millInfoId)
      },
      getCenterList (subZoneOfficeId) {
        return this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item.status === 1 && item.subzone_office_id === subZoneOfficeId)
      }
    }
}
</script>
<style>
th {
 border: 1px solid black!important;
 font-size:12px!important;
}
.notbold{
  font-weight:normal;
  text-align: center;
}
</style>
