import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri, orgId, currentLocale, reportTitle, newDatas, vm, search, searchResult, grandTotal) => {
  try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
            columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRowsHead = [
          [
            { text: vm.$t('elearning_config.fiscal_year') + ' : ' + (search.fiscal_year_id !== 0 ? (currentLocale === 'en' ? searchResult.fiscal_year : searchResult.fiscal_year_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('bsri_demandDIS.mill_name') + ' : ' + (search.mill_info_id !== 0 ? (currentLocale === 'en' ? searchResult.mill_name : searchResult.mill_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('bsri_demandDIS.subzone_office_name') + ' : ' + (search.subzone_office_i !== 0 ? (currentLocale === 'en' ? searchResult.subzone_office_name : searchResult.subzone_office_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('bsri_config.center_name') + ' : ' + (search.center_id !== 0 ? (currentLocale === 'en' ? searchResult.center_name : searchResult.center_name_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: '*',
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        const allRows = []
        const firstRows = [
          { text: vm.$t('globalTrans.sl_no'), rowSpan: 3, style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('bsri_config.center_name'), rowSpan: 3, style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('bsri_demandDIS.total_farmer'), rowSpan: 3, style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('bsri_config.cultivation_method_name'), colSpan: (vm.cultivationMethod.length * 2) + 2, style: 'th', alignment: 'center', bold: true }
        ]
        vm.cultivationMethod.filter((methodItem, index2) => {
          firstRows.push(
            {},
            {}
          )
        })
        firstRows.push({ text: vm.$t('globalTrans.total'), colSpan: 2, style: 'th', alignment: 'center', bold: true, rowSpan: 2 })
        allRows.push(firstRows)
        const newArray = [
          {},
          {},
          {}
        ]
        vm.cultivationMethod.filter(methodItem => {
          const methodTableColumn = {
            text: (i18n.locale === 'bn') ? methodItem.cultivationMethod_name_bn : methodItem.cultivationMethod_name,
            style: 'th',
            colSpan: 2,
            alignment: 'right'
          }
          newArray.push(methodTableColumn)
          newArray.push({})
        })
        newArray.push(
          { text: vm.$t('globalTrans.total'), colSpan: 2, style: 'th', alignment: 'center' },
          {}
        )
        allRows.push(newArray)
        const newUdArray = [
          {},
          {},
          {}
        ]
        vm.cultivationMethod.filter(methodItem => {
          newUdArray.push(
            {
              text: vm.$t('bsri_demandDIS.lnd_quantity'),
              style: 'th',
              alignment: 'center'
            },
            {
              text: vm.$t('bsri_demandDIS.amount_money'),
              style: 'th',
              alignment: 'center'
            }
          )
        })
        newUdArray.push(
          {
            text: vm.$t('bsri_demandDIS.lnd_quantity'),
            style: 'th',
            alignment: 'center'
          },
          {
            text: vm.$t('bsri_demandDIS.amount_money'),
            style: 'th',
            alignment: 'center'
          }
        )
        allRows.push(newUdArray)
        const dynamicData = []
        newDatas.filter((item, index) => {
          dynamicData.push(
            {
              text: vm.$n(index + 1),
              style: 'th',
              alignment: 'center'
            },
            {
              text: (i18n.locale === 'bn') ? item.center_name_bn : item.center_name,
              style: 'th',
              alignment: 'center'
            },
            {
              text: vm.$n(item.total_farmers),
              style: 'th',
              alignment: 'center'
            }
          )
          vm.cultivationMethod.filter((methodItem, index2) => {
            dynamicData.push(
              {
                text: item.cultivation_info[index2 + 1] ? vm.$n(item.cultivation_info[index2 + 1].land_quantity) : '0',
                style: 'th',
                alignment: 'center'
              },
              {
                text: item.cultivation_info[index2 + 1] ? vm.$n(item.cultivation_info[index2 + 1].amount) : '0',
                style: 'th',
                alignment: 'center'
              }
            )
          })
          dynamicData.push(
            {
              text: vm.$n(item.total_quantity),
              style: 'th',
              alignment: 'center'
            },
            {
              text: vm.$n(item.total_amount),
              style: 'th',
              alignment: 'center'
            }
          )
        })
        allRows.push(dynamicData)
        const newArrayFoot = [
          { text: vm.$t('globalTrans.total'), colSpan: 2, style: 'th', alignment: 'center', bold: true },
          {},
          { text: grandTotal.grand_total_farmer, style: 'th', alignment: 'center', bold: true },
          { text: '', colSpan: vm.cultivationMethod.length * 2, style: 'th', alignment: 'center', bold: true }
        ]
        const totalData = (vm.cultivationMethod.length) - 1
        for (let i = 0; i <= totalData; i++) {
          newArrayFoot.push({})
        }
        for (let i = 0; i <= totalData; i++) {
          if (i !== 0) {
            newArrayFoot.push({})
          }
        }
        newArrayFoot.push(
          { text: grandTotal.grand_total_quantity, style: 'th', alignment: 'center', bold: true },
          { text: grandTotal.grand_total_amount, style: 'th', alignment: 'center', bold: true }
        )
        allRows.push(newArrayFoot)
        pdfContent.push({
          table: {
            headerRows: 3,
            widths: '*',
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A3',
        pageOrientation: 'Landcape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 15 : 13,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 13,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          main_head: {
            fontSize: 9
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
